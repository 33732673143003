import React, { useEffect, useMemo, useState } from 'react';
import { BiddingDeclarationView, BiddingView, ExamView } from '../../graphql/types.gen.ts';
import { compareTwoBids, santizeId } from '../../helpers.ts';
import { IResult } from '../../pages/Game/Game.tsx';
import { Seats } from './Seats.tsx';
import './Sequence.scss';
import { SequenceItem } from './SequenceItem.tsx';

interface ComplexSequenceProps {
    drawnTest: ExamView;
    userBids: BiddingView[];
    handleResult: (r: IResult) => void;
    handleRecentBids: (b: BiddingView[]) => void;
    groupCode: string;
    currentDepth: number;
    result: IResult;
}

export const emptyBid: BiddingView = {
    bidCard: {
        color: null,
        value: null,
    },
    declaration: BiddingDeclarationView.Bid,
    shouldAlert: false,
};

export const ComplexSequence = (props: ComplexSequenceProps) => {
    const { drawnTest, userBids, handleRecentBids, result } = props;

    //TODO: maybe you can remove currentDepth
    const currentDepth = userBids.length;

    const [userAnswersWithNextSequences, setUserAnswersWithNextSequences] = useState<BiddingView[]>([]);

    useEffect(() => {
        if (result) return;
        const userWithAns = userBids.length
            ? userBids.flatMap((item, index) => {
                  return index === userBids.length - 1 && index !== drawnTest.definition.answers.length - 1 ? [item, ...drawnTest.definition.answers[index].biddingSequence, emptyBid] : [item, ...drawnTest.definition.answers[index].biddingSequence];
              })
            : [emptyBid];
        setUserAnswersWithNextSequences(userWithAns);
    }, [userBids, drawnTest]);

    const allBids = useMemo(() => [...drawnTest.definition.initialBiddingSequence, ...userAnswersWithNextSequences], [userAnswersWithNextSequences]);

    const [isInitLoading, setisInitLoading] = useState(true);
    const [wholeSeqAnimation, setWholeSeqAnimation] = useState(false);

    const classForSide = {
        0: 'W',
        1: 'S',
        2: 'E',
        3: 'N',
    };

    const classForStarted = {
        0: 'W',
        1: 'S',
        2: 'E',
        3: 'N',
    };

    const initAnimate = () => {
        setisInitLoading(true);
        setTimeout(() => {
            setisInitLoading(false);
        }, 500);
    };

    useEffect(() => {
        if (currentDepth === 0) {
            initAnimate();
        }
    }, [drawnTest]);

    const started = allBids ? classForStarted[allBids.length % 4] : null;

    const getColorByType = (type: string): string => {
        switch (type) {
            case 'PAS':
                return 'pass-green';
            case 'X':
                return 'ktr-red';
            case 'XX':
                return 'rktr-blue';
            default:
                return '';
        }
    };

    useEffect(() => {
        handleRecentBids(allBids);
    }, [allBids]);

    const orderedBidsWithClasses = useMemo(() => {
        if (!allBids) return null;
        return allBids.map((item, index) => {
            const y = index + 4 - (allBids.length % 4);
            const z = allBids.length % 4 || 4;
            const seq0123 = index < allBids.length % 4 ? index : y;
            const isEmptyBid = compareTwoBids(item, emptyBid);

            const classes = `sequence-item 
            shouldAlert-${item?.shouldAlert}    
          seq0123-${seq0123}
          index-${index}
          shouldShow-${index < z + 4 * currentDepth}
          ${classForSide[(allBids.length - index) % 4]}-bid 
          ${getColorByType(item?.bidCard?.color)}
          sequence-item-depth-${Math.floor(index / 4)}
          complex-sequence-item-${!wholeSeqAnimation ? seq0123 % 4 : ''} 
          ${isEmptyBid ? ' emptyBid' : ''}`;
            return <SequenceItem key={drawnTest?.id + item.bidCard?.color + item.bidCard?.value + index} item={item} classes={classes} />;
        });
    }, [allBids, currentDepth, isInitLoading]);
    return (
        <>
            <div className={`bids-space current-depth-${0} v-${santizeId(drawnTest?.vulnerability)}`} id="bids-space">
                <div className="test-sequence">
                    <Seats started={started} />
                    {!!allBids && !!orderedBidsWithClasses && orderedBidsWithClasses}
                </div>
            </div>
        </>
    );
};

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AccessToken = {
  __typename?: 'AccessToken';
  accessToken: Scalars['String']['output'];
};

export enum AccountActivationErrorReason {
  AlreadyActivated = 'ALREADY_ACTIVATED',
  TokenNotFound = 'TOKEN_NOT_FOUND'
}

export type AccountActivationErrorView = {
  __typename?: 'AccountActivationErrorView';
  reason: AccountActivationErrorReason;
};

export type AccountActivationInput = {
  accountActivationToken: Scalars['String']['input'];
};

export type AccountActivationResultView = AccountActivationErrorView | AccountActivationSuccessView;

export type AccountActivationSuccessView = {
  __typename?: 'AccountActivationSuccessView';
  activatedAt: Scalars['DateTime']['output'];
};

export enum BiddingCardColorView {
  Clubs = 'CLUBS',
  Diamonds = 'DIAMONDS',
  Hearts = 'HEARTS',
  NoTrumps = 'NO_TRUMPS',
  Spades = 'SPADES'
}

export type BiddingCardInput = {
  color: BiddingCardColorView;
  value: BiddingCardValueView;
};

export enum BiddingCardValueView {
  Five = 'FIVE',
  Four = 'FOUR',
  One = 'ONE',
  Seven = 'SEVEN',
  Six = 'SIX',
  Three = 'THREE',
  Two = 'TWO'
}

export type BiddingCardView = {
  __typename?: 'BiddingCardView';
  color: BiddingCardColorView;
  value: BiddingCardValueView;
};

export enum BiddingDeclarationView {
  Bid = 'BID',
  Double = 'DOUBLE',
  Pass = 'PASS',
  Redouble = 'REDOUBLE'
}

export type BiddingInput = {
  bidCard?: InputMaybe<BiddingCardInput>;
  declaration: BiddingDeclarationView;
  shouldAlert: Scalars['Boolean']['input'];
};

export type BiddingView = {
  __typename?: 'BiddingView';
  bidCard?: Maybe<BiddingCardView>;
  declaration: BiddingDeclarationView;
  shouldAlert: Scalars['Boolean']['output'];
};

export enum CardColorView {
  Clubs = 'CLUBS',
  Diamonds = 'DIAMONDS',
  Hearts = 'HEARTS',
  Spades = 'SPADES'
}

export type CardInput = {
  color: CardColorView;
  value: CardValueView;
};

export enum CardValueView {
  Ace = 'ACE',
  Eight = 'EIGHT',
  Five = 'FIVE',
  Four = 'FOUR',
  Jack = 'JACK',
  King = 'KING',
  Nine = 'NINE',
  Queen = 'QUEEN',
  Seven = 'SEVEN',
  Six = 'SIX',
  Ten = 'TEN',
  Three = 'THREE',
  Two = 'TWO'
}

export type CardView = {
  __typename?: 'CardView';
  color: CardColorView;
  value: CardValueView;
};

export enum CreateExamFailureReasonView {
  CreatorNotGroupInstructor = 'CREATOR_NOT_GROUP_INSTRUCTOR',
  CreatorNotGroupMember = 'CREATOR_NOT_GROUP_MEMBER',
  GroupNotFound = 'GROUP_NOT_FOUND'
}

export type CreateExamFailureView = {
  __typename?: 'CreateExamFailureView';
  reason: CreateExamFailureReasonView;
};

export type CreateExamInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  definition: ExamDefinitionInput;
  difficultyLevel: ExamDifficultyLevelView;
  groupCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tags: Array<Scalars['String']['input']>;
  vulnerability: ExamVulnerabilityView;
};

export type CreateExamSuccessView = {
  __typename?: 'CreateExamSuccessView';
  examId: Scalars['String']['output'];
  revisionId: Scalars['String']['output'];
};

export type CreateExamView = CreateExamFailureView | CreateExamSuccessView;

export type CreateJoinRequestFailureView = {
  __typename?: 'CreateJoinRequestFailureView';
  failureReason: Scalars['String']['output'];
};

export type CreateJoinRequestInput = {
  groupCode: Scalars['String']['input'];
};

export type CreateJoinRequestSuccessView = {
  __typename?: 'CreateJoinRequestSuccessView';
  groupCode: Scalars['String']['output'];
};

export type CreateJoinRequestView = CreateJoinRequestFailureView | CreateJoinRequestSuccessView;

export type DeleteAccountInput = {
  feedback?: Scalars['String']['input'];
};

export type DeleteAccountView = {
  __typename?: 'DeleteAccountView';
  success: Scalars['Boolean']['output'];
};

export enum DeleteExamFailureReasonView {
  AlreadyDeleted = 'ALREADY_DELETED',
  CallerNotGroupInstructor = 'CALLER_NOT_GROUP_INSTRUCTOR',
  CallerNotGroupMember = 'CALLER_NOT_GROUP_MEMBER',
  ExamNotFound = 'EXAM_NOT_FOUND',
  GroupNotFound = 'GROUP_NOT_FOUND'
}

export type DeleteExamInput = {
  examId: Scalars['String']['input'];
  groupCode: Scalars['String']['input'];
};

export type DeleteExamView = {
  __typename?: 'DeleteExamView';
  reason?: Maybe<DeleteExamFailureReasonView>;
};

export type ExamAnswerInput = {
  biddingSequence: Array<BiddingInput>;
  halfAnswers: Array<BiddingInput>;
  matchingAnswers: Array<BiddingInput>;
};

export type ExamAnswerView = {
  __typename?: 'ExamAnswerView';
  biddingSequence: Array<BiddingView>;
  halfAnswers: Array<BiddingView>;
  matchingAnswers: Array<BiddingView>;
};

export type ExamDefinitionInput = {
  answers: Array<ExamAnswerInput>;
  cards: Array<CardInput>;
  initialBiddingSequence: Array<BiddingInput>;
};

export type ExamDefinitionView = {
  __typename?: 'ExamDefinitionView';
  answers: Array<ExamAnswerView>;
  cards: Array<CardView>;
  initialBiddingSequence: Array<BiddingView>;
};

export enum ExamDifficultyLevelView {
  Easy = 'EASY',
  Hard = 'HARD',
  Medium = 'MEDIUM'
}

export type ExamResultsSummary = {
  __typename?: 'ExamResultsSummary';
  passedExamsNumber: Scalars['Float']['output'];
  tag: Scalars['String']['output'];
  totalExamsNumber: Scalars['Float']['output'];
};

/** Usage: provide 'id' field to select specific exam OR 'random' field to get random exam using provided filters OR 'next' field to get next exam using provided filters and id to start from. Do not provide both fields at the same time, it will return error. */
export type ExamSelectionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  next?: InputMaybe<NextExamSelectionInput>;
  random?: InputMaybe<ExamsFiltersInput>;
};

export type ExamView = {
  __typename?: 'ExamView';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: UserPublicModelView;
  definition: ExamDefinitionView;
  difficultyLevel: ExamDifficultyLevelView;
  id: Scalars['String']['output'];
  modifiedBy: Array<UserPublicModelView>;
  myHighestResult?: Maybe<HighestExamResultView>;
  name: Scalars['String']['output'];
  revisionId: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vulnerability: ExamVulnerabilityView;
};

export type ExamViewPaginationResultSetView = {
  __typename?: 'ExamViewPaginationResultSetView';
  items: Array<ExamView>;
  options: PaginationOptionsView;
  total: Scalars['Float']['output'];
};

export enum ExamVulnerabilityView {
  Both = 'BOTH',
  EwRed = 'EW_RED',
  None = 'NONE',
  NotSpecified = 'NOT_SPECIFIED',
  NsRed = 'NS_RED'
}

export type ExamsFiltersInput = {
  difficultyLevel: Array<ExamDifficultyLevelView>;
  ignoreExamsConnectedToLessons?: Scalars['Boolean']['input'];
  tags: Array<Scalars['String']['input']>;
};

export type ExamsSelectionInput = {
  filters: ExamsFiltersInput;
  pagination: PaginationOptionsInput;
};

export enum ExamsSummaryOrderBy {
  PassedExamsNumber = 'PASSED_EXAMS_NUMBER',
  Tag = 'TAG',
  TotalExamsNumber = 'TOTAL_EXAMS_NUMBER'
}

export enum ExamsSummaryOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ExamsSummaryOrdering = {
  orderBy: ExamsSummaryOrderBy;
  orderDirection: ExamsSummaryOrderDirection;
};

export type ExamsSummarySelection = {
  ordering: ExamsSummaryOrdering;
};

export enum GroupCreateFailureReason {
  GroupCodeAlreadyExists = 'GROUP_CODE_ALREADY_EXISTS'
}

export type GroupCreateFailureView = {
  __typename?: 'GroupCreateFailureView';
  reason: GroupCreateFailureReason;
};

export type GroupCreateInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GroupCreateSuccessView = {
  __typename?: 'GroupCreateSuccessView';
  groupCode: Scalars['String']['output'];
};

export type GroupCreateView = GroupCreateFailureView | GroupCreateSuccessView;

export enum GroupMemberRoleView {
  Instructor = 'INSTRUCTOR',
  Pupil = 'PUPIL'
}

export type GroupMemberView = {
  __typename?: 'GroupMemberView';
  examsSummary: Array<ExamResultsSummary>;
  memberSince: Scalars['DateTime']['output'];
  role: GroupMemberRoleView;
  score: Scalars['Float']['output'];
  user: UserPublicModelView;
};


export type GroupMemberViewExamsSummaryArgs = {
  input: ExamsSummarySelection;
};

export type GroupMemberViewPaginationResultSetView = {
  __typename?: 'GroupMemberViewPaginationResultSetView';
  items: Array<GroupMemberView>;
  options: PaginationOptionsView;
  total: Scalars['Float']['output'];
};

export type GroupMembersSelectionInput = {
  pagination: PaginationOptionsInput;
};

export enum GroupSwitchSaveErrorReason {
  GroupNotFound = 'GROUP_NOT_FOUND',
  NotAMember = 'NOT_A_MEMBER'
}

export type GroupSwitchSaveErrorView = {
  __typename?: 'GroupSwitchSaveErrorView';
  reason: GroupSwitchSaveErrorReason;
};

export type GroupSwitchSaveInput = {
  groupCode: Scalars['String']['input'];
};

export type GroupSwitchSaveResultView = GroupSwitchSaveErrorView | GroupSwitchSaveSuccessView;

export type GroupSwitchSaveSuccessView = {
  __typename?: 'GroupSwitchSaveSuccessView';
  groupId: Scalars['String']['output'];
};

export type GroupView = {
  __typename?: 'GroupView';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: UserPublicModelView;
  /** Usage: provide 'id' field to select specific exam OR 'random' field to get random exam using provided filters OR 'next' field to get next exam using provided filters and id to start from. Do not provide both fields at the same time, it will return error. */
  exam?: Maybe<ExamView>;
  exams: ExamViewPaginationResultSetView;
  joinRequests: JoinRequestViewPaginationResultSetView;
  leaderboard: Array<LeaderView>;
  lesson: LessonView;
  lessons: LessonViewPaginationResultSetView;
  me?: Maybe<GroupMemberView>;
  members: GroupMemberViewPaginationResultSetView;
  name: Scalars['String']['output'];
  possibleExamTags: Array<Scalars['String']['output']>;
  possibleLessonTags: Array<Scalars['String']['output']>;
  visibility: Scalars['String']['output'];
};


export type GroupViewExamArgs = {
  input: ExamSelectionInput;
};


export type GroupViewExamsArgs = {
  input: ExamsSelectionInput;
};


export type GroupViewJoinRequestsArgs = {
  input: JoinRequestsSelectionInput;
};


export type GroupViewLeaderboardArgs = {
  selection: LeaderboardSelectionInput;
};


export type GroupViewLessonArgs = {
  input: LessonSelectionInput;
};


export type GroupViewLessonsArgs = {
  input: LessonsSelectionInput;
};


export type GroupViewMembersArgs = {
  input: GroupMembersSelectionInput;
};

export enum HighestExamResultView {
  Failed = 'FAILED',
  FullSuccess = 'FULL_SUCCESS',
  HalfSuccess = 'HALF_SUCCESS'
}

export type JoinRequestReviewFailureView = {
  __typename?: 'JoinRequestReviewFailureView';
  reason: Scalars['String']['output'];
};

export type JoinRequestReviewInput = {
  accept: Scalars['Boolean']['input'];
  groupCode: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type JoinRequestReviewResultView = JoinRequestReviewFailureView | JoinRequestReviewSuccessView;

export type JoinRequestReviewSuccessView = {
  __typename?: 'JoinRequestReviewSuccessView';
  groupCode: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type JoinRequestReviewView = {
  __typename?: 'JoinRequestReviewView';
  accepted: Scalars['Boolean']['output'];
  reviewedAt: Scalars['DateTime']['output'];
  reviewedBy: UserPublicModelView;
};

export type JoinRequestView = {
  __typename?: 'JoinRequestView';
  createdAt: Scalars['DateTime']['output'];
  createdBy: UserPublicModelView;
  review?: Maybe<JoinRequestReviewView>;
};

export type JoinRequestViewPaginationResultSetView = {
  __typename?: 'JoinRequestViewPaginationResultSetView';
  items: Array<JoinRequestView>;
  options: PaginationOptionsView;
  total: Scalars['Float']['output'];
};

export type JoinRequestsSelectionFiltersInput = {
  reviewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JoinRequestsSelectionInput = {
  filters: JoinRequestsSelectionFiltersInput;
  pagination: PaginationOptionsInput;
};

export type LeaderView = {
  __typename?: 'LeaderView';
  score: Scalars['Float']['output'];
  totalExamsNumber: Scalars['Float']['output'];
  totalPassedExamsNumber: Scalars['Float']['output'];
  user: UserPublicModelView;
};

export type LeaderboardSelectionInput = {
  pagination: PaginationOptionsInput;
  setup: LeaderboardSetup;
};

export type LeaderboardSetup = {
  type: LeaderboardType;
};

export enum LeaderboardType {
  HighestPtRatio = 'HIGHEST_PT_RATIO',
  HighestScore = 'HIGHEST_SCORE',
  MostActive = 'MOST_ACTIVE'
}

export enum LessonCreateFailureReasonView {
  CreatorNotGroupInstructor = 'CREATOR_NOT_GROUP_INSTRUCTOR',
  GroupNotFound = 'GROUP_NOT_FOUND',
  InvalidSlug = 'INVALID_SLUG',
  LessonToInsertBeforeNotFound = 'LESSON_TO_INSERT_BEFORE_NOT_FOUND',
  SlugAlreadyExists = 'SLUG_ALREADY_EXISTS',
  TitleAlreadyExists = 'TITLE_ALREADY_EXISTS'
}

export type LessonCreateFailureView = {
  __typename?: 'LessonCreateFailureView';
  reason: LessonCreateFailureReasonView;
};

/** If revision slug is empty, it will be generated automatically using title */
export type LessonCreateInput = {
  groupCode: Scalars['String']['input'];
  insertBeforeLessonSlug?: InputMaybe<Scalars['String']['input']>;
  revision: LessonRevisionInput;
};

export type LessonCreateSuccessView = {
  __typename?: 'LessonCreateSuccessView';
  lessonSlug: Scalars['String']['output'];
};

export type LessonCreateView = LessonCreateFailureView | LessonCreateSuccessView;

export enum LessonDeleteFailureReasonView {
  CallerNotGroupInstructor = 'CALLER_NOT_GROUP_INSTRUCTOR',
  GroupNotFound = 'GROUP_NOT_FOUND',
  LessonNotFound = 'LESSON_NOT_FOUND'
}

export type LessonDeleteFailureView = {
  __typename?: 'LessonDeleteFailureView';
  reason: LessonDeleteFailureReasonView;
};

export type LessonDeleteInput = {
  groupCode: Scalars['String']['input'];
  lessonSlug: Scalars['String']['input'];
};

export type LessonDeleteSuccessView = {
  __typename?: 'LessonDeleteSuccessView';
  deletedAt: Scalars['DateTime']['output'];
};

export type LessonDeleteView = LessonDeleteFailureView | LessonDeleteSuccessView;

export enum LessonDifficultyLevelView {
  Advanced = 'ADVANCED',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE'
}

export enum LessonOrderChangeFailureReasonView {
  CallerNotGroupInstructor = 'CALLER_NOT_GROUP_INSTRUCTOR',
  GroupNotFound = 'GROUP_NOT_FOUND',
  LessonToMoveBeforeNotFound = 'LESSON_TO_MOVE_BEFORE_NOT_FOUND',
  LessonToMoveNotFound = 'LESSON_TO_MOVE_NOT_FOUND'
}

export type LessonOrderChangeFailureView = {
  __typename?: 'LessonOrderChangeFailureView';
  reason: LessonOrderChangeFailureReasonView;
};

export type LessonOrderChangeInput = {
  groupCode: Scalars['String']['input'];
  lessonToMoveSlug: Scalars['String']['input'];
  /** Slug of the lesson to move this lesson before. If null, move to the end of the lessons list */
  moveBeforeLessonSlug?: InputMaybe<Scalars['String']['input']>;
};

export type LessonOrderChangeSuccessView = {
  __typename?: 'LessonOrderChangeSuccessView';
  lessonSlug: Scalars['String']['output'];
};

export type LessonOrderChangeView = LessonOrderChangeFailureView | LessonOrderChangeSuccessView;

export enum LessonRevisionCreateFailureReasonView {
  CreatorNotGroupInstructor = 'CREATOR_NOT_GROUP_INSTRUCTOR',
  GroupNotFound = 'GROUP_NOT_FOUND',
  InvalidSlug = 'INVALID_SLUG',
  LessonNotFound = 'LESSON_NOT_FOUND',
  TitleAlreadyExists = 'TITLE_ALREADY_EXISTS'
}

export type LessonRevisionCreateFailureView = {
  __typename?: 'LessonRevisionCreateFailureView';
  reason: LessonRevisionCreateFailureReasonView;
};

export type LessonRevisionCreateInput = {
  groupCode: Scalars['String']['input'];
  revision: LessonRevisionInput;
};

export type LessonRevisionCreateSuccessView = {
  __typename?: 'LessonRevisionCreateSuccessView';
  lessonSlug: Scalars['String']['output'];
};

export type LessonRevisionCreateView = LessonRevisionCreateFailureView | LessonRevisionCreateSuccessView;

export type LessonRevisionInput = {
  content: Scalars['String']['input'];
  description: Scalars['String']['input'];
  difficultyLevel: LessonDifficultyLevelView;
  homeworkExamIds: Array<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  tags: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

/** Usage: provide 'slug' field to select specific lesson OR 'next' / 'previous' field to get next lesson using provided filters and id to start from. Do not provide multiple fields at the same time, it will return error. Provide exactly one field. */
export type LessonSelectionInput = {
  next?: InputMaybe<NextLessonSelectionInput>;
  previous?: InputMaybe<PreviousLessonSelectionInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type LessonView = {
  __typename?: 'LessonView';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: UserPublicModelView;
  description: Scalars['String']['output'];
  difficultyLevel: LessonDifficultyLevelView;
  hasHomework: Scalars['Boolean']['output'];
  homework: Array<ExamView>;
  isHomeworkDone: Scalars['Boolean']['output'];
  modifiedBy?: Maybe<Array<UserPublicModelView>>;
  slug: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LessonViewPaginationResultSetView = {
  __typename?: 'LessonViewPaginationResultSetView';
  items: Array<LessonView>;
  options: PaginationOptionsView;
  total: Scalars['Float']['output'];
};

export type LessonsFiltersInput = {
  difficultyLevel: Array<LessonDifficultyLevelView>;
  tags: Array<Scalars['String']['input']>;
};

export type LessonsSelectionInput = {
  filters: LessonsFiltersInput;
  pagination: PaginationOptionsInput;
};

export type MarketingConsentUpdateInput = {
  marketingConsent: Scalars['Boolean']['input'];
};

export type MeView = {
  __typename?: 'MeView';
  email: Scalars['String']['output'];
  groups: Array<GroupView>;
  id: Scalars['String']['output'];
  marketingConsent: Scalars['Boolean']['output'];
  recentlyUsedGroupId?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  accountActivate: AccountActivationResultView;
  accountDelete: DeleteAccountView;
  examCreate: CreateExamView;
  examDelete: DeleteExamView;
  examResultsSave: SaveExamResultsResult;
  examUpdate: UpdateExamView;
  /** Creates a new PRIVATE group. If you want to make group public, use change-group-visibility cli command. */
  groupCreate: GroupCreateView;
  groupJoinRequestCreate: CreateJoinRequestView;
  groupJoinRequestReview: JoinRequestReviewResultView;
  groupSwitchSave: GroupSwitchSaveResultView;
  lessonCreate: LessonCreateView;
  lessonDelete: LessonDeleteView;
  lessonOrderChange: LessonOrderChangeView;
  lessonRevisionCreate: LessonRevisionCreateView;
  marketingConsentUpdate: MeView;
  passwordChange: PasswordChangeView;
  passwordReset: PasswordResetOutput;
  passwordResetRequest: PasswordResetRequestOutput;
  signIn: SignInResult;
  signUp: SignUpResultView;
};


export type MutationAccountActivateArgs = {
  input: AccountActivationInput;
};


export type MutationAccountDeleteArgs = {
  input: DeleteAccountInput;
};


export type MutationExamCreateArgs = {
  input: CreateExamInput;
};


export type MutationExamDeleteArgs = {
  input: DeleteExamInput;
};


export type MutationExamResultsSaveArgs = {
  input: SaveExamResultsInput;
};


export type MutationExamUpdateArgs = {
  input: UpdateExamInput;
};


export type MutationGroupCreateArgs = {
  input: GroupCreateInput;
};


export type MutationGroupJoinRequestCreateArgs = {
  input: CreateJoinRequestInput;
};


export type MutationGroupJoinRequestReviewArgs = {
  input: JoinRequestReviewInput;
};


export type MutationGroupSwitchSaveArgs = {
  input: GroupSwitchSaveInput;
};


export type MutationLessonCreateArgs = {
  input: LessonCreateInput;
};


export type MutationLessonDeleteArgs = {
  input: LessonDeleteInput;
};


export type MutationLessonOrderChangeArgs = {
  input: LessonOrderChangeInput;
};


export type MutationLessonRevisionCreateArgs = {
  input: LessonRevisionCreateInput;
};


export type MutationMarketingConsentUpdateArgs = {
  input: MarketingConsentUpdateInput;
};


export type MutationPasswordChangeArgs = {
  input: PasswordChangeInput;
};


export type MutationPasswordResetArgs = {
  input: PasswordResetInput;
};


export type MutationPasswordResetRequestArgs = {
  input: PasswordResetRequestInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type NextExamSelectionInput = {
  filters: ExamsFiltersInput;
  startFromId: Scalars['String']['input'];
};

export type NextLessonSelectionInput = {
  afterSlug: Scalars['String']['input'];
  filters: LessonsFiltersInput;
};

export type PaginationOptionsInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type PaginationOptionsView = {
  __typename?: 'PaginationOptionsView';
  limit: Scalars['Float']['output'];
  offset: Scalars['Float']['output'];
};

export type PasswordChangeInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type PasswordChangeView = {
  __typename?: 'PasswordChangeView';
  success: Scalars['Boolean']['output'];
};

export type PasswordResetInput = {
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  passwordResetToken: Scalars['String']['input'];
};

export type PasswordResetOutput = {
  __typename?: 'PasswordResetOutput';
  success: Scalars['Boolean']['output'];
};

export type PasswordResetRequestInput = {
  email: Scalars['String']['input'];
};

export type PasswordResetRequestOutput = {
  __typename?: 'PasswordResetRequestOutput';
  email: Scalars['String']['output'];
  resetTokenExpiresAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PreviousLessonSelectionInput = {
  beforeSlug: Scalars['String']['input'];
  filters: LessonsFiltersInput;
};

export type Query = {
  __typename?: 'Query';
  group?: Maybe<GroupView>;
  me: MeView;
  publicGroups: Array<GroupView>;
};


export type QueryGroupArgs = {
  code: Scalars['String']['input'];
};

export type SaveExamResultsFailureView = {
  __typename?: 'SaveExamResultsFailureView';
  reason: Scalars['String']['output'];
};

export type SaveExamResultsInput = {
  answers: Array<BiddingInput>;
  examId: Scalars['String']['input'];
  groupCode: Scalars['String']['input'];
  revisionId: Scalars['String']['input'];
};

export type SaveExamResultsResult = SaveExamResultsFailureView | SaveExamResultsSuccessView;

export type SaveExamResultsSuccessView = {
  __typename?: 'SaveExamResultsSuccessView';
  examResultId: Scalars['String']['output'];
};

export type SignInInput = {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignInResult = {
  __typename?: 'SignInResult';
  result?: Maybe<AccessToken>;
};

export enum SignUpErrorReason {
  EmailExists = 'EMAIL_EXISTS',
  IncorrectEmail = 'INCORRECT_EMAIL',
  IncorrectPassword = 'INCORRECT_PASSWORD',
  IncorrectUsername = 'INCORRECT_USERNAME',
  UsernameExists = 'USERNAME_EXISTS'
}

export type SignUpErrorView = {
  __typename?: 'SignUpErrorView';
  reason: SignUpErrorReason;
};

export type SignUpInput = {
  email: Scalars['String']['input'];
  marketingConsent?: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type SignUpResultView = SignUpErrorView | SignUpSuccessView;

export type SignUpSuccessView = {
  __typename?: 'SignUpSuccessView';
  accountId: Scalars['String']['output'];
};

export enum UpdateExamFailureReasonView {
  CallerNotGroupInstructor = 'CALLER_NOT_GROUP_INSTRUCTOR',
  CallerNotGroupMember = 'CALLER_NOT_GROUP_MEMBER',
  ExamNotFound = 'EXAM_NOT_FOUND',
  GroupNotFound = 'GROUP_NOT_FOUND'
}

export type UpdateExamFailureView = {
  __typename?: 'UpdateExamFailureView';
  reason: UpdateExamFailureReasonView;
};

export type UpdateExamInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  definition: ExamDefinitionInput;
  difficultyLevel: ExamDifficultyLevelView;
  examId: Scalars['String']['input'];
  groupCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tags: Array<Scalars['String']['input']>;
  vulnerability: ExamVulnerabilityView;
};

export type UpdateExamSuccessView = {
  __typename?: 'UpdateExamSuccessView';
  examId: Scalars['String']['output'];
  revisionId: Scalars['String']['output'];
};

export type UpdateExamView = UpdateExamFailureView | UpdateExamSuccessView;

export type UserPublicModelView = {
  __typename?: 'UserPublicModelView';
  id: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

import React from 'react';
import { About } from './pages/About/About.tsx';
import ActivateAccountPage from './pages/ActivateAccountPage/ActivateAccountPage.tsx';
import Backoffice from './pages/Backoffice/Backoffice.tsx';
import { BackofficeInfo } from './pages/BackofficeInfo/BackofficeInfo.tsx';
import { CheckEmail } from './pages/CheckEmail/CheckEmail.tsx';
import { ChooseGroup } from './pages/FormPage/ChooseGroup/ChooseGroup.tsx';
import LoginPage from './pages/FormPage/LoginPage/LoginPage.tsx';
import PasswordReset from './pages/FormPage/PasswordReset/PasswordReset.tsx';
import PasswordResetRequest from './pages/FormPage/PasswordResetRequest/PasswordResetRequest.tsx';
import SignUpPage from './pages/FormPage/SignUpPage/SignUpPage.tsx';
import Game from './pages/Game/Game.tsx';
import { Home } from './pages/Home/Home.tsx';
import { AddLesson } from './pages/Lessons/AddLesson.tsx';
import { Lessons } from './pages/Lessons/Lessons.tsx';
import SingleLesson from './pages/Lessons/SingleLesson/SingleLesson.tsx';
import { MarketingConsent } from './pages/MarketingConsent/MarketingConsent.tsx';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy.tsx';
import { Profil } from './pages/Profil/Profil.tsx';
import { Statistics } from './pages/Statistics/Statistics.tsx';
import { Terms } from './pages/Terms/Terms.tsx';

export const ROUTER = [
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/licytacja',
        element: <Game />,
    },
    {
        path: '/utworz-grupe',
        element: <BackofficeInfo />,
    },
    {
        path: '/o-mnie',
        element: <About />,
    },
    {
        path: 'backoffice',
        element: <Backoffice />,
    },
    {
        path: 'login/activated',
        element: <LoginPage activated={true} />,
    },
    {
        path: 'login',
        element: <LoginPage activated={false} />,
    },
    {
        path: 'signup',
        element: <SignUpPage />,
    },
    {
        path: 'activate-account/:token',
        element: <ActivateAccountPage />,
    },
    {
        path: 'check-email',
        element: <CheckEmail />,
    },
    {
        path: 'choose-group',
        element: <ChooseGroup />,
    },
    {
        path: 'profil',
        element: <Profil />,
    },
    {
        path: 'studenci',
        element: <Statistics />,
    },
    {
        path: 'nauka',
        element: <Lessons />,
    },
    {
        path: 'dodaj-lekcje',
        element: <AddLesson />,
    },
    {
        path: ':chosenGroup/lekcja/:titleId',
        element: <SingleLesson />,
    },
    {
        path: 'lekcje/edytuj/:titleId',
        element: <AddLesson />,
    },
    {
        path: 'regulamin',
        element: <Terms />,
    },
    {
        path: 'polityka-prywatnosci',
        element: <PrivacyPolicy />,
    },
    {
        path: 'marketing',
        element: <MarketingConsent />,
    },
    {
        path: 'password-reset-request',
        element: <PasswordResetRequest />,
    },
    {
        path: 'reset-password/:id',
        element: <PasswordReset />,
    },
];

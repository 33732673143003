import React from 'react';
import { useIsMobile } from '../../../helpers/useIsMobile.ts';

export const CustomizedTick = (props) => {
    const { isMobile } = useIsMobile();
    function trimString(str) {
        if (str.length > 12 && !isMobile) {
            return str.substring(0, 10) + '...';
        } else if (str.length > 6 && isMobile) {
            return str.substring(0, 5) + '...';
        }
        return str;
    }
    const { x, y, payload } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                {trimString(payload.value)}
            </text>
        </g>
    );
};

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Footer } from '../../../components/Footer/Footer.tsx';
import { Header } from '../../../components/Header/Header.tsx';
import { usePasswordResetMutation } from './PasswordReset.gen.ts';
import { PasswordResetSchema } from './PasswordResetSchema.ts';

const PasswordReset = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [passwordReset] = usePasswordResetMutation();

    const handleSubmit = async (values) => {
        await passwordReset({
            variables: {
                passwordResetInput2: {
                    email: values.email,
                    newPassword: values.password,
                    passwordResetToken: id,
                },
            },
        })
            .then((res) => {
                if (res?.data?.passwordReset?.success === false) {
                    toast.error('Wystąpił nieoczekiwany błąd');
                    console.log(res.errors);
                    return;
                } else {
                    toast.success('Operacja przebiegła pomyślnie.');
                    navigate('/login');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="formPage">
            <Header />
            <div className="formPage__content">
                <p className="formPage__heading">
                    <span className="formPage__heading--licytapka">Licytapka</span> to Twoja partnerka w nauce licytacji brydżowych
                </p>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={PasswordResetSchema}
                    onSubmit={handleSubmit}
                >
                    {(formik) => {
                        const { errors, touched, isValid, dirty } = formik;

                        return (
                            <div className="formPage__formContainer formPage__formContainer--short">
                                <div className="formPage__col formPage__col--pass formPage__col--left">
                                    <h2 className="formPage__title">Ustaw nowe hasło</h2>
                                    <Form>
                                        <div className="formPage__formGroup form-row">
                                            <Field type="email" placeholder="Email" name="email" id="email" className={`formPage__formControl formPage__formControl--pass ${errors.email && touched.email ? 'input-error' : ''}`} />
                                            <ErrorMessage name="email" component="span" className="error" />
                                        </div>
                                        <div className="formPage__formGroup form-row">
                                            <Field placeholder="Hasło*" type="password" name="password" id="password" className={`formPage__formControl ${errors.password && touched.password ? 'input-error' : ''}`} />
                                            <ErrorMessage name="password" component="span" className="error" />
                                        </div>
                                        <button type="submit" className={`button formPage__button${!(dirty && isValid) ? ' disabled' : ''}`} disabled={!(dirty && isValid)}>
                                            Zapisz
                                        </button>
                                    </Form>
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </div>
            <Footer />
        </div>
    );
};
export default PasswordReset;

import React from 'react';
import { BiddingView } from '../../graphql/types.gen.ts';
import { SequenceItem } from '../Sequence/SequenceItem.tsx';
import './Explanation.css';
interface ExplanationProps {
    answers: BiddingView[];
}

export const Explanation = (props: ExplanationProps) => {
    const { answers } = props;

    return (
        <div className="explanation">
            <p className="tested__text">PRAWIDŁOWE ODPOWIEDZI</p>
            <div className="explanation__wrapper">
                {answers.map((a, i) => {
                    return (
                        <div className="explanation__item" key={i}>
                            <span>{i > 0 ? ' lub ' : ''}</span>
                            <SequenceItem item={a} classes={`sequence-item-show sequence-item--explanation`} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

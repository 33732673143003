import * as Types from '../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GroupTagsQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type GroupTagsQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', possibleExamTags: Array<string> } | null };

export type ExamUpdateMutationVariables = Types.Exact<{
  input: Types.UpdateExamInput;
}>;


export type ExamUpdateMutation = { __typename?: 'Mutation', examUpdate: { __typename?: 'UpdateExamFailureView', reason: Types.UpdateExamFailureReasonView } | { __typename?: 'UpdateExamSuccessView', examId: string, revisionId: string } };

export type ExamCreateMutationVariables = Types.Exact<{
  input: Types.CreateExamInput;
}>;


export type ExamCreateMutation = { __typename?: 'Mutation', examCreate: { __typename?: 'CreateExamFailureView', reason: Types.CreateExamFailureReasonView } | { __typename?: 'CreateExamSuccessView', examId: string, revisionId: string } };

export type ExamRemoveMutationVariables = Types.Exact<{
  input: Types.DeleteExamInput;
}>;


export type ExamRemoveMutation = { __typename?: 'Mutation', examDelete: { __typename?: 'DeleteExamView', reason?: Types.DeleteExamFailureReasonView | null } };

export type GetExamsFromGroupQueryVariables = Types.Exact<{
  input: Types.ExamsSelectionInput;
  code: Types.Scalars['String']['input'];
}>;


export type GetExamsFromGroupQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', exams: { __typename?: 'ExamViewPaginationResultSetView', total: number, items: Array<{ __typename?: 'ExamView', comment?: string | null, createdAt: any, difficultyLevel: Types.ExamDifficultyLevelView, id: string, name: string, revisionId: string, tags: Array<string>, updatedAt: any, vulnerability: Types.ExamVulnerabilityView, createdBy: { __typename?: 'UserPublicModelView', username: string, id: string }, definition: { __typename?: 'ExamDefinitionView', answers: Array<{ __typename?: 'ExamAnswerView', halfAnswers: Array<{ __typename?: 'BiddingView', shouldAlert: boolean, declaration: Types.BiddingDeclarationView, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, matchingAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', value: Types.BiddingCardValueView, color: Types.BiddingCardColorView } | null }>, biddingSequence: Array<{ __typename?: 'BiddingView', shouldAlert: boolean, declaration: Types.BiddingDeclarationView, bidCard?: { __typename?: 'BiddingCardView', value: Types.BiddingCardValueView, color: Types.BiddingCardColorView } | null }> }>, cards: Array<{ __typename?: 'CardView', value: Types.CardValueView, color: Types.CardColorView }>, initialBiddingSequence: Array<{ __typename?: 'BiddingView', shouldAlert: boolean, declaration: Types.BiddingDeclarationView, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> }, modifiedBy: Array<{ __typename?: 'UserPublicModelView', username: string, id: string }> }> } } | null };


export const GroupTagsDocument = gql`
    query GroupTags($code: String!) {
  group(code: $code) {
    possibleExamTags
  }
}
    `;

/**
 * __useGroupTagsQuery__
 *
 * To run a query within a React component, call `useGroupTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupTagsQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGroupTagsQuery(baseOptions: Apollo.QueryHookOptions<GroupTagsQuery, GroupTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupTagsQuery, GroupTagsQueryVariables>(GroupTagsDocument, options);
      }
export function useGroupTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupTagsQuery, GroupTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupTagsQuery, GroupTagsQueryVariables>(GroupTagsDocument, options);
        }
export function useGroupTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupTagsQuery, GroupTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupTagsQuery, GroupTagsQueryVariables>(GroupTagsDocument, options);
        }
export type GroupTagsQueryHookResult = ReturnType<typeof useGroupTagsQuery>;
export type GroupTagsLazyQueryHookResult = ReturnType<typeof useGroupTagsLazyQuery>;
export type GroupTagsSuspenseQueryHookResult = ReturnType<typeof useGroupTagsSuspenseQuery>;
export type GroupTagsQueryResult = Apollo.QueryResult<GroupTagsQuery, GroupTagsQueryVariables>;
export const ExamUpdateDocument = gql`
    mutation ExamUpdate($input: UpdateExamInput!) {
  examUpdate(input: $input) {
    ... on UpdateExamFailureView {
      reason
    }
    ... on UpdateExamSuccessView {
      examId
      revisionId
    }
  }
}
    `;
export type ExamUpdateMutationFn = Apollo.MutationFunction<ExamUpdateMutation, ExamUpdateMutationVariables>;

/**
 * __useExamUpdateMutation__
 *
 * To run a mutation, you first call `useExamUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examUpdateMutation, { data, loading, error }] = useExamUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ExamUpdateMutation, ExamUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExamUpdateMutation, ExamUpdateMutationVariables>(ExamUpdateDocument, options);
      }
export type ExamUpdateMutationHookResult = ReturnType<typeof useExamUpdateMutation>;
export type ExamUpdateMutationResult = Apollo.MutationResult<ExamUpdateMutation>;
export type ExamUpdateMutationOptions = Apollo.BaseMutationOptions<ExamUpdateMutation, ExamUpdateMutationVariables>;
export const ExamCreateDocument = gql`
    mutation ExamCreate($input: CreateExamInput!) {
  examCreate(input: $input) {
    ... on CreateExamFailureView {
      reason
    }
    ... on CreateExamSuccessView {
      examId
      revisionId
    }
  }
}
    `;
export type ExamCreateMutationFn = Apollo.MutationFunction<ExamCreateMutation, ExamCreateMutationVariables>;

/**
 * __useExamCreateMutation__
 *
 * To run a mutation, you first call `useExamCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examCreateMutation, { data, loading, error }] = useExamCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamCreateMutation(baseOptions?: Apollo.MutationHookOptions<ExamCreateMutation, ExamCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExamCreateMutation, ExamCreateMutationVariables>(ExamCreateDocument, options);
      }
export type ExamCreateMutationHookResult = ReturnType<typeof useExamCreateMutation>;
export type ExamCreateMutationResult = Apollo.MutationResult<ExamCreateMutation>;
export type ExamCreateMutationOptions = Apollo.BaseMutationOptions<ExamCreateMutation, ExamCreateMutationVariables>;
export const ExamRemoveDocument = gql`
    mutation ExamRemove($input: DeleteExamInput!) {
  examDelete(input: $input) {
    reason
  }
}
    `;
export type ExamRemoveMutationFn = Apollo.MutationFunction<ExamRemoveMutation, ExamRemoveMutationVariables>;

/**
 * __useExamRemoveMutation__
 *
 * To run a mutation, you first call `useExamRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examRemoveMutation, { data, loading, error }] = useExamRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ExamRemoveMutation, ExamRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExamRemoveMutation, ExamRemoveMutationVariables>(ExamRemoveDocument, options);
      }
export type ExamRemoveMutationHookResult = ReturnType<typeof useExamRemoveMutation>;
export type ExamRemoveMutationResult = Apollo.MutationResult<ExamRemoveMutation>;
export type ExamRemoveMutationOptions = Apollo.BaseMutationOptions<ExamRemoveMutation, ExamRemoveMutationVariables>;
export const GetExamsFromGroupDocument = gql`
    query GetExamsFromGroup($input: ExamsSelectionInput!, $code: String!) {
  group(code: $code) {
    exams(input: $input) {
      total
      items {
        comment
        createdAt
        createdBy {
          username
          id
        }
        definition {
          answers {
            halfAnswers {
              shouldAlert
              declaration
              bidCard {
                color
                value
              }
            }
            matchingAnswers {
              bidCard {
                value
                color
              }
              declaration
              shouldAlert
            }
            biddingSequence {
              bidCard {
                value
                color
              }
              shouldAlert
              declaration
            }
          }
          cards {
            value
            color
          }
          initialBiddingSequence {
            shouldAlert
            declaration
            bidCard {
              color
              value
            }
          }
        }
        difficultyLevel
        id
        modifiedBy {
          username
          id
        }
        name
        revisionId
        tags
        updatedAt
        vulnerability
      }
    }
  }
}
    `;

/**
 * __useGetExamsFromGroupQuery__
 *
 * To run a query within a React component, call `useGetExamsFromGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamsFromGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamsFromGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetExamsFromGroupQuery(baseOptions: Apollo.QueryHookOptions<GetExamsFromGroupQuery, GetExamsFromGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExamsFromGroupQuery, GetExamsFromGroupQueryVariables>(GetExamsFromGroupDocument, options);
      }
export function useGetExamsFromGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExamsFromGroupQuery, GetExamsFromGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExamsFromGroupQuery, GetExamsFromGroupQueryVariables>(GetExamsFromGroupDocument, options);
        }
export function useGetExamsFromGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetExamsFromGroupQuery, GetExamsFromGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetExamsFromGroupQuery, GetExamsFromGroupQueryVariables>(GetExamsFromGroupDocument, options);
        }
export type GetExamsFromGroupQueryHookResult = ReturnType<typeof useGetExamsFromGroupQuery>;
export type GetExamsFromGroupLazyQueryHookResult = ReturnType<typeof useGetExamsFromGroupLazyQuery>;
export type GetExamsFromGroupSuspenseQueryHookResult = ReturnType<typeof useGetExamsFromGroupSuspenseQuery>;
export type GetExamsFromGroupQueryResult = Apollo.QueryResult<GetExamsFromGroupQuery, GetExamsFromGroupQueryVariables>;
import * as Types from '../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GroupJoinRequestCreateMutationVariables = Types.Exact<{
  input: Types.CreateJoinRequestInput;
}>;


export type GroupJoinRequestCreateMutation = { __typename?: 'Mutation', groupJoinRequestCreate: { __typename?: 'CreateJoinRequestFailureView', failureReason: string } | { __typename?: 'CreateJoinRequestSuccessView', groupCode: string } };

export type AccountDeleteMutationVariables = Types.Exact<{
  input: Types.DeleteAccountInput;
}>;


export type AccountDeleteMutation = { __typename?: 'Mutation', accountDelete: { __typename?: 'DeleteAccountView', success: boolean } };

export type PasswordChangeMutationVariables = Types.Exact<{
  input: Types.PasswordChangeInput;
}>;


export type PasswordChangeMutation = { __typename?: 'Mutation', passwordChange: { __typename?: 'PasswordChangeView', success: boolean } };

export type GroupSwitchSaveMutationVariables = Types.Exact<{
  input: Types.GroupSwitchSaveInput;
}>;


export type GroupSwitchSaveMutation = { __typename?: 'Mutation', groupSwitchSave: { __typename?: 'GroupSwitchSaveErrorView', reason: Types.GroupSwitchSaveErrorReason } | { __typename?: 'GroupSwitchSaveSuccessView', groupId: string } };


export const GroupJoinRequestCreateDocument = gql`
    mutation GroupJoinRequestCreate($input: CreateJoinRequestInput!) {
  groupJoinRequestCreate(input: $input) {
    ... on CreateJoinRequestFailureView {
      failureReason
    }
    ... on CreateJoinRequestSuccessView {
      groupCode
    }
  }
}
    `;
export type GroupJoinRequestCreateMutationFn = Apollo.MutationFunction<GroupJoinRequestCreateMutation, GroupJoinRequestCreateMutationVariables>;

/**
 * __useGroupJoinRequestCreateMutation__
 *
 * To run a mutation, you first call `useGroupJoinRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupJoinRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupJoinRequestCreateMutation, { data, loading, error }] = useGroupJoinRequestCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupJoinRequestCreateMutation(baseOptions?: Apollo.MutationHookOptions<GroupJoinRequestCreateMutation, GroupJoinRequestCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupJoinRequestCreateMutation, GroupJoinRequestCreateMutationVariables>(GroupJoinRequestCreateDocument, options);
      }
export type GroupJoinRequestCreateMutationHookResult = ReturnType<typeof useGroupJoinRequestCreateMutation>;
export type GroupJoinRequestCreateMutationResult = Apollo.MutationResult<GroupJoinRequestCreateMutation>;
export type GroupJoinRequestCreateMutationOptions = Apollo.BaseMutationOptions<GroupJoinRequestCreateMutation, GroupJoinRequestCreateMutationVariables>;
export const AccountDeleteDocument = gql`
    mutation AccountDelete($input: DeleteAccountInput!) {
  accountDelete(input: $input) {
    success
  }
}
    `;
export type AccountDeleteMutationFn = Apollo.MutationFunction<AccountDeleteMutation, AccountDeleteMutationVariables>;

/**
 * __useAccountDeleteMutation__
 *
 * To run a mutation, you first call `useAccountDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountDeleteMutation, { data, loading, error }] = useAccountDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountDeleteMutation(baseOptions?: Apollo.MutationHookOptions<AccountDeleteMutation, AccountDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountDeleteMutation, AccountDeleteMutationVariables>(AccountDeleteDocument, options);
      }
export type AccountDeleteMutationHookResult = ReturnType<typeof useAccountDeleteMutation>;
export type AccountDeleteMutationResult = Apollo.MutationResult<AccountDeleteMutation>;
export type AccountDeleteMutationOptions = Apollo.BaseMutationOptions<AccountDeleteMutation, AccountDeleteMutationVariables>;
export const PasswordChangeDocument = gql`
    mutation PasswordChange($input: PasswordChangeInput!) {
  passwordChange(input: $input) {
    success
  }
}
    `;
export type PasswordChangeMutationFn = Apollo.MutationFunction<PasswordChangeMutation, PasswordChangeMutationVariables>;

/**
 * __usePasswordChangeMutation__
 *
 * To run a mutation, you first call `usePasswordChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangeMutation, { data, loading, error }] = usePasswordChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordChangeMutation(baseOptions?: Apollo.MutationHookOptions<PasswordChangeMutation, PasswordChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordChangeMutation, PasswordChangeMutationVariables>(PasswordChangeDocument, options);
      }
export type PasswordChangeMutationHookResult = ReturnType<typeof usePasswordChangeMutation>;
export type PasswordChangeMutationResult = Apollo.MutationResult<PasswordChangeMutation>;
export type PasswordChangeMutationOptions = Apollo.BaseMutationOptions<PasswordChangeMutation, PasswordChangeMutationVariables>;
export const GroupSwitchSaveDocument = gql`
    mutation GroupSwitchSave($input: GroupSwitchSaveInput!) {
  groupSwitchSave(input: $input) {
    ... on GroupSwitchSaveErrorView {
      reason
    }
    ... on GroupSwitchSaveSuccessView {
      groupId
    }
  }
}
    `;
export type GroupSwitchSaveMutationFn = Apollo.MutationFunction<GroupSwitchSaveMutation, GroupSwitchSaveMutationVariables>;

/**
 * __useGroupSwitchSaveMutation__
 *
 * To run a mutation, you first call `useGroupSwitchSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupSwitchSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupSwitchSaveMutation, { data, loading, error }] = useGroupSwitchSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupSwitchSaveMutation(baseOptions?: Apollo.MutationHookOptions<GroupSwitchSaveMutation, GroupSwitchSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupSwitchSaveMutation, GroupSwitchSaveMutationVariables>(GroupSwitchSaveDocument, options);
      }
export type GroupSwitchSaveMutationHookResult = ReturnType<typeof useGroupSwitchSaveMutation>;
export type GroupSwitchSaveMutationResult = Apollo.MutationResult<GroupSwitchSaveMutation>;
export type GroupSwitchSaveMutationOptions = Apollo.BaseMutationOptions<GroupSwitchSaveMutation, GroupSwitchSaveMutationVariables>;
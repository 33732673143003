import { useEffect, useState } from 'react';
import { BiddingView, ExamView } from '../../../graphql/types.gen.ts';
import { IBidReference } from '../components/BackofficeTestEditMode.js';

export enum AnswerCorrectness {
    FULL = 'FULL',
    HALF = 'HALF',
}

export interface IBackofficeSeqItem {
    bids: BiddingView[];
    answers: BiddingView[];
    halfAnswers: BiddingView[];
}
export const useGetBackofficeBidsArray = (test: ExamView) => {
    const [modArr, setModArr] = useState<IBackofficeSeqItem[]>([]);
    const reverseTransformOfTest = (arr: IBackofficeSeqItem[]) => {
        const initialBiddingSequence = arr[0]?.bids.length ? removeTypenames(arr[0]?.bids).map((item) => (item ? { ...item } : null)) : [];
        return {
            definition: {
                initialBiddingSequence,
                answers: arr.map((item, depth) => {
                    return {
                        matchingAnswers: removeTypenames(item.answers).map((item) => (item ? { ...item } : null)),
                        halfAnswers: removeTypenames(item.halfAnswers).map((item) => (item ? { ...item } : null)),
                        biddingSequence: modArr[depth + 1]?.bids.length ? removeTypenames(modArr[depth + 1]?.bids).map((item) => (item ? { ...item } : null)) : [],
                    };
                }),
            },
        };
    };
    const initTransformOfTest = (test: ExamView) => {
        const preBids = Array.from<undefined>({
            length: 3 - test.definition.initialBiddingSequence.length,
        });
        setModArr([
            ...test.definition.answers.map((obj, index) => {
                return { bids: index === 0 ? [...preBids, ...test.definition.initialBiddingSequence] : test.definition.answers[index - 1].biddingSequence, answers: obj.matchingAnswers, halfAnswers: obj.halfAnswers };
            }),
        ]);
    };

    const validateExamObject = (examObject: IBackofficeSeqItem[]) => {
        if (!examObject || !examObject[0]?.answers) return { validate: false, c: '1' };

        for (let i = 0; i < examObject.length; i++) {
            if (!examObject[i].answers[0] || examObject[i]?.bids.length !== 3) return { validate: false, c: '2' };
            if (i >= 1 && examObject[i].bids.some((bid) => !bid)) return { validate: false, c: '3' };
            if (i === 0) {
                for (let j = 0; j < examObject[0].bids.length; j++) {
                    if (examObject[0].bids[j]) {
                        for (let k = j; k < examObject[0].bids.length; k++) {
                            if (!examObject[0].bids[k]) return { validate: false, c: '4' };
                        }
                    }
                }
            }
        }

        return { validate: true, c: null };
    };

    useEffect(() => {
        initTransformOfTest(test);
    }, [test]);

    function removeTypenames(obj: any): any {
        if (Array.isArray(obj)) {
            return obj.map(removeTypenames);
        } else if (obj !== null && typeof obj === 'object') {
            const newObj: any = {};
            for (const key of Object.keys(obj)) {
                if (key !== '__typename') {
                    newObj[key] = removeTypenames(obj[key]);
                }
            }
            return newObj;
        } else {
            return obj;
        }
    }

    const updateArr = (bid: BiddingView | null, bidRef: IBidReference) => {
        setModArr((prev) => {
            let newArr = JSON.parse(JSON.stringify(prev));

            while (bidRef.index >= newArr.length) {
                newArr.push({ bids: [null, null, null], answers: [null], halfAnswers: [null] });
            }
            newArr[`${bidRef.index}`][`${bidRef.type}`][`${bidRef.innerIndex}`] = bid;
            for (let i = newArr.length - 1; i >= 0; i--) {
                if (newArr[i].bids.every((bid) => bid === null) && newArr[i].answers.every((answer) => answer === null) && newArr[i].halfAnswers.every((halfAnswer) => halfAnswer === null)) {
                    newArr.pop();
                } else {
                    if (newArr[i].answers.every((answer) => answer === null) && newArr[i].answers.length >= 1) {
                        newArr[i].answers.pop();
                    }
                    if (newArr[i].halfAnswers.every((halfAnswer) => halfAnswer === null) && newArr[i].halfAnswers.length >= 1) {
                        newArr[i].halfAnswers.pop();
                    }
                }
            }
            return newArr;
        });
    };

    const answersToSave = {
        initialBiddingSequence: reverseTransformOfTest(modArr).definition.initialBiddingSequence,
        answers: reverseTransformOfTest(modArr).definition.answers,
    };

    const resetArr = () => {
        initTransformOfTest(test);
    };
    return { arr: modArr, updateArr, resetArr, answersToSave, validateExamObject };
};

import React, { useMemo } from 'react';
import { BiddingInput, BiddingView } from '../../graphql/types.gen';
import { IResult } from '../../pages/Game/Game.tsx';
import { Draw } from '../Drawer/Draw.tsx';
import { Explanation } from '../Explanation/Explanation.tsx';
import { SequenceItem } from '../Sequence/SequenceItem.tsx';

const resultDictionary = {
    FULL: 'Dobrze',
    BAD: 'Źle',
    HALF: 'To dobra odpowiedź, ale są lepsze',
    MISSING_ALERT: 'Dobra odzywka, zły alert',
};

type AnswerProps = {
    result: IResult;
    bestAnswers: BiddingView[];
    userBid: BiddingInput;
    drawTest: () => void;
    repeatTest: () => void;
    nextTest: () => void;
    forLesson?: boolean;
    comment?: string;
};
const Answer = (props: AnswerProps) => {
    const { result, userBid, bestAnswers, drawTest, repeatTest, nextTest, forLesson, comment } = props;

    const text = useMemo(() => resultDictionary[result], [result]);

    return (
        <>
            <p className={`tested__result tested__result--${result}`}>{text}</p>
            <p className="tested__text tested__comment">{comment}</p>
            <div className="tested">
                <div>
                    <p className="tested__text">TWOJA ODPOWIEDŹ</p>
                    <SequenceItem item={userBid} classes={`sequence-item-show sequence-item--explanation`} />
                </div>

                {(result === 'BAD' || result === 'HALF' || result === 'MISSING_ALERT') && <Explanation answers={bestAnswers} />}
            </div>
            {/* {(result === 'BAD' || result === 'HALF') && (
                <button className="button button__repeat" onClick={repeatTest}>
                    Ponów <Repeat />
                </button>
            )}
            <button className="button button__next" onClick={nextTest}>
                Kolejne <Arrow />
            </button> */}
            {!forLesson && (
                <button className="button" onClick={drawTest}>
                    Losuj <Draw />
                </button>
            )}
        </>
    );
};

export default Answer;

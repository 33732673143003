import React from 'react';
import './Section.scss';

export const Section = (props) => {
    const { title, desc, variant = 'primary', children } = props;

    return (
        <div className={`section section--${variant}`}>
            {title || desc ? (
                <div className="container">
                    <h2 className="section__title">{title}</h2>
                    {desc && <p className="section__desc">{desc}</p>}
                    {children}
                </div>
            ) : (
                children
            )}
        </div>
    );
};

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Footer } from '../../../components/Footer/Footer.tsx';
import { Header } from '../../../components/Header/Header.tsx';
import '../FormPage.scss';
import { usePasswordResetRequestMutation } from './PasswordResetRequest.gen.ts';
import { passwordResetRequestSchema } from './passwordResetRequestSchema.ts';

const PasswordResetRequest = () => {
    const navigate = useNavigate();

    const [passwordResetRequest] = usePasswordResetRequestMutation();

    const handleSubmit = async (values) => {
        await passwordResetRequest({
            variables: {
                input: values,
            },
        })
            .then((res) => {
                if (!res?.data?.passwordResetRequest?.email) {
                    toast.error('Wystąpił nieoczekiwany błąd');
                    console.log(res.errors);
                    return;
                } else {
                    toast.success('Operacja przebiegła pomyślnie. Sprawdź swoją skrzynkę mailową');
                    navigate('/login');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="formPage">
            <Header />
            <div className="formPage__content">
                <p className="formPage__heading">
                    <span className="formPage__heading--licytapka">Licytapka</span> to Twoja partnerka w nauce licytacji brydżowych
                </p>
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={passwordResetRequestSchema}
                    onSubmit={handleSubmit}
                >
                    {(formik) => {
                        const { errors, touched, isValid, dirty } = formik;

                        return (
                            <div className="formPage__formContainer formPage__formContainer--short">
                                <div className="formPage__col formPage__col--pass formPage__col--left">
                                    <h2 className="formPage__title">Przypomnienie hasła</h2>
                                    <p className="formPage__desc">jeśli Twój adres email znajduje się w naszej bazie, wyślemy Ci wiadomość z linkiem do resetu hasła</p>
                                    <Form>
                                        <div className="formPage__formGroup form-row">
                                            <Field type="email" placeholder="Email" name="email" id="email" className={`formPage__formControl formPage__formControl--pass ${errors.email && touched.email ? 'input-error' : ''}`} />
                                            <ErrorMessage name="email" component="span" className="error" />
                                        </div>
                                        <button type="submit" className={`button formPage__button${!(dirty && isValid) ? ' disabled' : ''}`} disabled={!(dirty && isValid)}>
                                            Zresetuj hasło
                                        </button>
                                    </Form>
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </div>
            <Footer />
        </div>
    );
};
export default PasswordResetRequest;

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button/Button.tsx';
import { Footer } from '../../components/Footer/Footer.tsx';
import { Header } from '../../components/Header/Header.tsx';
import Hero from '../../components/Hero/Hero.tsx';
import { ExamsSummaryOrderBy, ExamsSummaryOrderDirection } from '../../graphql/types.gen.ts';
import { useValidateToken } from '../../helpers/useValidateToken.ts';
import { useLastGroupQuery } from '../FormPage/LoginPage/loginPage.gen.ts';
import './Statistics.scss';
import { GetJoiningRequestestsDocument, useBackofficeStatisticsQuery, useGetJoiningRequestestsQuery, useGroupJoinRequestReviewMutation } from './statistics.gen.ts';
import { useStatistics } from './useStatistics.ts';
export const Statistics = () => {
    useValidateToken({ optional: true });
    const { data: chosenGroupData } = useLastGroupQuery();
    const groupCode = chosenGroupData?.me?.recentlyUsedGroupId || 'general';
    const [groupJoinRequestReviewMutation, { data: groupJoinRequestReviewData }] = useGroupJoinRequestReviewMutation({ refetchQueries: [GetJoiningRequestestsDocument] });
    const { data } = useBackofficeStatisticsQuery({
        variables: {
            input: {
                pagination: {
                    limit: 100,
                    offset: 0,
                },
            },
            input2: {
                ordering: {
                    orderBy: ExamsSummaryOrderBy.Tag,
                    orderDirection: ExamsSummaryOrderDirection.Desc,
                },
            },
            code: groupCode,
        },
    });
    useEffect(() => {
        if (groupJoinRequestReviewData) {
            if (groupJoinRequestReviewData.groupJoinRequestReview.__typename === 'JoinRequestReviewSuccessView') {
                toast.success('Operacja wykonana pomyślnie');
            } else {
                toast.error('Nie udało się wykonać operacji');
            }
        }
    }, [groupJoinRequestReviewData]);

    const { data: joinRequestsData } = useGetJoiningRequestestsQuery({
        variables: {
            input: {
                filters: {
                    reviewed: false,
                },
                pagination: {
                    limit: 100,
                    offset: 0,
                },
            },
            code: groupCode,
        },
    });

    const myStatistics = data?.group?.me;
    const membersStatistics = data?.group?.members.items;
    const myExamResults = myStatistics?.examsSummary;
    const { sumExams } = useStatistics(myExamResults);

    const shouldBeRed = (ratio: number) => (ratio < 50 ? '#ffe7eb' : 'white');

    return (
        <div className="statistics light">
            <div className="content">
                <Header light={true} />
                <Hero title="Studenci" />
                <div className="statistics__container container">
                    <Paper className="statistics__paper statistics__paper--center">
                        <h2>Prośby o dołączenie do grupy:</h2>
                        {joinRequestsData?.group?.joinRequests?.items.length === 0 && <p>Brak nowych prośb o dołączenie do grupy</p>}
                        {joinRequestsData?.group?.joinRequests?.items.map((request) => {
                            const d = new Date(request?.createdAt);
                            const datestring = d.toISOString().slice(0, 10);
                            return (
                                <div className="row request">
                                    <p>{request.createdBy.username}</p>
                                    <p>{datestring}</p>
                                    <Button
                                        className="button"
                                        variant="text"
                                        onClick={() => {
                                            groupJoinRequestReviewMutation({
                                                variables: {
                                                    input: {
                                                        accept: true,
                                                        groupCode: groupCode,
                                                        userId: request.createdBy?.id,
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        Zaakceptuj
                                    </Button>
                                    <Button
                                        className="button"
                                        variant="textRed"
                                        onClick={() => {
                                            groupJoinRequestReviewMutation({
                                                variables: {
                                                    input: {
                                                        accept: false,
                                                        groupCode: groupCode,
                                                        userId: request.createdBy?.id,
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        Odrzuć
                                    </Button>
                                </div>
                            );
                        })}
                    </Paper>
                    <Paper className="statistics__paper statistics__paper--center">
                        <h2 className="statistics__paperTitle">Statystyki Twoich uczniów</h2>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Użytkownik</TableCell>
                                        <TableCell align="center">Procent dobrze zrobionych zadań</TableCell>
                                        <TableCell align="center">Liczba zrobionych zadań ogółem</TableCell>
                                        {membersStatistics &&
                                            membersStatistics[0]?.examsSummary.map((item) => {
                                                return <TableCell align="center">{item.tag}</TableCell>;
                                            })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {membersStatistics &&
                                        membersStatistics.map((member) => {
                                            const memberStatistics = sumExams(member.examsSummary);
                                            return (
                                                <TableRow key={member.user.username} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="member">
                                                        {member.user.username}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ background: `${shouldBeRed(memberStatistics?.find((item) => item.id === 'passed').ratioNumber)}` }}>
                                                        {memberStatistics?.find((item) => item.id === 'passed').ratio}%
                                                    </TableCell>
                                                    <TableCell align="center">{member.examsSummary.reduce((acc, item) => acc + item.totalExamsNumber, 0)}</TableCell>
                                                    {member.examsSummary.map((item) => (
                                                        <TableCell align="center" style={{ background: `${shouldBeRed((item.passedExamsNumber * 100) / item.totalExamsNumber)}` }}>
                                                            {item.passedExamsNumber}/{item.totalExamsNumber}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <p>
                        Chciałbyś, aby pojawiły się tu inne rodzaje statystyk? Daj mi znać:{' '}
                        <a href="mailTo:magdalena@licytapka.pl" className="link">
                            magdalena@licytapka.pl
                        </a>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

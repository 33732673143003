import * as Types from '../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GroupsQueryVariables = Types.Exact<{
  input: Types.ExamsSelectionInput;
}>;


export type GroupsQuery = { __typename?: 'Query', me: { __typename?: 'MeView', groups: Array<{ __typename?: 'GroupView', code: string, exams: { __typename?: 'ExamViewPaginationResultSetView', total: number, items: Array<{ __typename?: 'ExamView', difficultyLevel: Types.ExamDifficultyLevelView, id: string, name: string, comment?: string | null, tags: Array<string>, updatedAt: any, vulnerability: Types.ExamVulnerabilityView, definition: { __typename?: 'ExamDefinitionView', answers: Array<{ __typename?: 'ExamAnswerView', biddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, halfAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, matchingAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> }>, cards: Array<{ __typename?: 'CardView', color: Types.CardColorView, value: Types.CardValueView }>, initialBiddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> } }>, options: { __typename?: 'PaginationOptionsView', limit: number, offset: number } } }> } };

export type ExamsFromGroupQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  input: Types.ExamsSelectionInput;
}>;


export type ExamsFromGroupQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', code: string, exams: { __typename?: 'ExamViewPaginationResultSetView', total: number, items: Array<{ __typename?: 'ExamView', revisionId: string, difficultyLevel: Types.ExamDifficultyLevelView, id: string, name: string, tags: Array<string>, updatedAt: any, vulnerability: Types.ExamVulnerabilityView, comment?: string | null, definition: { __typename?: 'ExamDefinitionView', answers: Array<{ __typename?: 'ExamAnswerView', biddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, halfAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, matchingAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> }>, cards: Array<{ __typename?: 'CardView', color: Types.CardColorView, value: Types.CardValueView }>, initialBiddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> } }>, options: { __typename?: 'PaginationOptionsView', limit: number, offset: number } } } | null };

export type ExamQueryVariables = Types.Exact<{
  examInput2: Types.ExamSelectionInput;
}>;


export type ExamQuery = { __typename?: 'Query', me: { __typename?: 'MeView', groups: Array<{ __typename?: 'GroupView', exam?: { __typename?: 'ExamView', revisionId: string, difficultyLevel: Types.ExamDifficultyLevelView, id: string, name: string, tags: Array<string>, updatedAt: any, vulnerability: Types.ExamVulnerabilityView, definition: { __typename?: 'ExamDefinitionView', answers: Array<{ __typename?: 'ExamAnswerView', biddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, halfAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, matchingAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> }>, cards: Array<{ __typename?: 'CardView', color: Types.CardColorView, value: Types.CardValueView }>, initialBiddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> } } | null }> } };

export type ExamsQueryVariables = Types.Exact<{
  input: Types.ExamsSelectionInput;
  code: Types.Scalars['String']['input'];
}>;


export type ExamsQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', exams: { __typename?: 'ExamViewPaginationResultSetView', items: Array<{ __typename?: 'ExamView', revisionId: string, difficultyLevel: Types.ExamDifficultyLevelView, id: string, name: string, tags: Array<string>, updatedAt: any, vulnerability: Types.ExamVulnerabilityView, comment?: string | null, definition: { __typename?: 'ExamDefinitionView', answers: Array<{ __typename?: 'ExamAnswerView', biddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, halfAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, matchingAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> }>, cards: Array<{ __typename?: 'CardView', color: Types.CardColorView, value: Types.CardValueView }>, initialBiddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> } }> } } | null };

export type ExamFromGroupQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  input: Types.ExamSelectionInput;
}>;


export type ExamFromGroupQuery = { __typename?: 'Query', group?: { __typename?: 'GroupView', exam?: { __typename?: 'ExamView', comment?: string | null, difficultyLevel: Types.ExamDifficultyLevelView, id: string, tags: Array<string>, name: string, updatedAt: any, vulnerability: Types.ExamVulnerabilityView, revisionId: string, definition: { __typename?: 'ExamDefinitionView', answers: Array<{ __typename?: 'ExamAnswerView', biddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, halfAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, matchingAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> }>, cards: Array<{ __typename?: 'CardView', color: Types.CardColorView, value: Types.CardValueView }>, initialBiddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> } } | null } | null };

export type PublicGroupsQueryVariables = Types.Exact<{
  input: Types.ExamSelectionInput;
}>;


export type PublicGroupsQuery = { __typename?: 'Query', publicGroups: Array<{ __typename?: 'GroupView', exam?: { __typename?: 'ExamView', difficultyLevel: Types.ExamDifficultyLevelView, id: string, name: string, tags: Array<string>, comment?: string | null, updatedAt: any, vulnerability: Types.ExamVulnerabilityView, revisionId: string, definition: { __typename?: 'ExamDefinitionView', answers: Array<{ __typename?: 'ExamAnswerView', biddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, halfAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }>, matchingAnswers: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> }>, cards: Array<{ __typename?: 'CardView', color: Types.CardColorView, value: Types.CardValueView }>, initialBiddingSequence: Array<{ __typename?: 'BiddingView', declaration: Types.BiddingDeclarationView, shouldAlert: boolean, bidCard?: { __typename?: 'BiddingCardView', color: Types.BiddingCardColorView, value: Types.BiddingCardValueView } | null }> } } | null }> };

export type PublicGroupsItemsQueryVariables = Types.Exact<{
  input: Types.ExamsSelectionInput;
}>;


export type PublicGroupsItemsQuery = { __typename?: 'Query', publicGroups: Array<{ __typename?: 'GroupView', exams: { __typename?: 'ExamViewPaginationResultSetView', items: Array<{ __typename?: 'ExamView', id: string }> } }> };

export type ExamResultsSaveMutationVariables = Types.Exact<{
  input: Types.SaveExamResultsInput;
}>;


export type ExamResultsSaveMutation = { __typename?: 'Mutation', examResultsSave: { __typename?: 'SaveExamResultsFailureView', reason: string } | { __typename?: 'SaveExamResultsSuccessView', examResultId: string } };


export const GroupsDocument = gql`
    query Groups($input: ExamsSelectionInput!) {
  me {
    groups {
      code
      exams(input: $input) {
        items {
          definition {
            answers {
              biddingSequence {
                bidCard {
                  color
                  value
                }
                declaration
                shouldAlert
              }
              halfAnswers {
                bidCard {
                  color
                  value
                }
                declaration
                shouldAlert
              }
              matchingAnswers {
                bidCard {
                  color
                  value
                }
                declaration
                shouldAlert
              }
            }
            cards {
              color
              value
            }
            initialBiddingSequence {
              bidCard {
                color
                value
              }
              declaration
              shouldAlert
            }
          }
          difficultyLevel
          id
          name
          comment
          tags
          updatedAt
          vulnerability
        }
        options {
          limit
          offset
        }
        total
      }
    }
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export function useGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsSuspenseQueryHookResult = ReturnType<typeof useGroupsSuspenseQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const ExamsFromGroupDocument = gql`
    query ExamsFromGroup($code: String!, $input: ExamsSelectionInput!) {
  group(code: $code) {
    code
    exams(input: $input) {
      items {
        definition {
          answers {
            biddingSequence {
              bidCard {
                color
                value
              }
              declaration
              shouldAlert
            }
            halfAnswers {
              bidCard {
                color
                value
              }
              declaration
              shouldAlert
            }
            matchingAnswers {
              bidCard {
                color
                value
              }
              declaration
              shouldAlert
            }
          }
          cards {
            color
            value
          }
          initialBiddingSequence {
            bidCard {
              color
              value
            }
            declaration
            shouldAlert
          }
        }
        revisionId
        difficultyLevel
        id
        name
        tags
        updatedAt
        vulnerability
        comment
      }
      options {
        limit
        offset
      }
      total
    }
  }
}
    `;

/**
 * __useExamsFromGroupQuery__
 *
 * To run a query within a React component, call `useExamsFromGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamsFromGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamsFromGroupQuery({
 *   variables: {
 *      code: // value for 'code'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamsFromGroupQuery(baseOptions: Apollo.QueryHookOptions<ExamsFromGroupQuery, ExamsFromGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExamsFromGroupQuery, ExamsFromGroupQueryVariables>(ExamsFromGroupDocument, options);
      }
export function useExamsFromGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExamsFromGroupQuery, ExamsFromGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExamsFromGroupQuery, ExamsFromGroupQueryVariables>(ExamsFromGroupDocument, options);
        }
export function useExamsFromGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExamsFromGroupQuery, ExamsFromGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExamsFromGroupQuery, ExamsFromGroupQueryVariables>(ExamsFromGroupDocument, options);
        }
export type ExamsFromGroupQueryHookResult = ReturnType<typeof useExamsFromGroupQuery>;
export type ExamsFromGroupLazyQueryHookResult = ReturnType<typeof useExamsFromGroupLazyQuery>;
export type ExamsFromGroupSuspenseQueryHookResult = ReturnType<typeof useExamsFromGroupSuspenseQuery>;
export type ExamsFromGroupQueryResult = Apollo.QueryResult<ExamsFromGroupQuery, ExamsFromGroupQueryVariables>;
export const ExamDocument = gql`
    query Exam($examInput2: ExamSelectionInput!) {
  me {
    groups {
      exam(input: $examInput2) {
        definition {
          answers {
            biddingSequence {
              bidCard {
                color
                value
              }
              declaration
              shouldAlert
            }
            halfAnswers {
              bidCard {
                color
                value
              }
              declaration
              shouldAlert
            }
            matchingAnswers {
              bidCard {
                color
                value
              }
              declaration
              shouldAlert
            }
          }
          cards {
            color
            value
          }
          initialBiddingSequence {
            bidCard {
              color
              value
            }
            declaration
            shouldAlert
          }
        }
        revisionId
        difficultyLevel
        id
        name
        tags
        updatedAt
        vulnerability
      }
    }
  }
}
    `;

/**
 * __useExamQuery__
 *
 * To run a query within a React component, call `useExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamQuery({
 *   variables: {
 *      examInput2: // value for 'examInput2'
 *   },
 * });
 */
export function useExamQuery(baseOptions: Apollo.QueryHookOptions<ExamQuery, ExamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExamQuery, ExamQueryVariables>(ExamDocument, options);
      }
export function useExamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExamQuery, ExamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExamQuery, ExamQueryVariables>(ExamDocument, options);
        }
export function useExamSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExamQuery, ExamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExamQuery, ExamQueryVariables>(ExamDocument, options);
        }
export type ExamQueryHookResult = ReturnType<typeof useExamQuery>;
export type ExamLazyQueryHookResult = ReturnType<typeof useExamLazyQuery>;
export type ExamSuspenseQueryHookResult = ReturnType<typeof useExamSuspenseQuery>;
export type ExamQueryResult = Apollo.QueryResult<ExamQuery, ExamQueryVariables>;
export const ExamsDocument = gql`
    query Exams($input: ExamsSelectionInput!, $code: String!) {
  group(code: $code) {
    exams(input: $input) {
      items {
        definition {
          answers {
            biddingSequence {
              bidCard {
                color
                value
              }
              declaration
              shouldAlert
            }
            halfAnswers {
              bidCard {
                color
                value
              }
              declaration
              shouldAlert
            }
            matchingAnswers {
              bidCard {
                color
                value
              }
              declaration
              shouldAlert
            }
          }
          cards {
            color
            value
          }
          initialBiddingSequence {
            bidCard {
              color
              value
            }
            declaration
            shouldAlert
          }
        }
        revisionId
        difficultyLevel
        id
        name
        tags
        updatedAt
        vulnerability
        comment
      }
    }
  }
}
    `;

/**
 * __useExamsQuery__
 *
 * To run a query within a React component, call `useExamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useExamsQuery(baseOptions: Apollo.QueryHookOptions<ExamsQuery, ExamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExamsQuery, ExamsQueryVariables>(ExamsDocument, options);
      }
export function useExamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExamsQuery, ExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExamsQuery, ExamsQueryVariables>(ExamsDocument, options);
        }
export function useExamsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExamsQuery, ExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExamsQuery, ExamsQueryVariables>(ExamsDocument, options);
        }
export type ExamsQueryHookResult = ReturnType<typeof useExamsQuery>;
export type ExamsLazyQueryHookResult = ReturnType<typeof useExamsLazyQuery>;
export type ExamsSuspenseQueryHookResult = ReturnType<typeof useExamsSuspenseQuery>;
export type ExamsQueryResult = Apollo.QueryResult<ExamsQuery, ExamsQueryVariables>;
export const ExamFromGroupDocument = gql`
    query ExamFromGroup($code: String!, $input: ExamSelectionInput!) {
  group(code: $code) {
    exam(input: $input) {
      definition {
        answers {
          biddingSequence {
            bidCard {
              color
              value
            }
            declaration
            shouldAlert
          }
          halfAnswers {
            bidCard {
              color
              value
            }
            declaration
            shouldAlert
          }
          matchingAnswers {
            bidCard {
              color
              value
            }
            declaration
            shouldAlert
          }
        }
        cards {
          color
          value
        }
        initialBiddingSequence {
          bidCard {
            color
            value
          }
          declaration
          shouldAlert
        }
      }
      comment
      difficultyLevel
      id
      tags
      name
      updatedAt
      vulnerability
      revisionId
    }
  }
}
    `;

/**
 * __useExamFromGroupQuery__
 *
 * To run a query within a React component, call `useExamFromGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamFromGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamFromGroupQuery({
 *   variables: {
 *      code: // value for 'code'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamFromGroupQuery(baseOptions: Apollo.QueryHookOptions<ExamFromGroupQuery, ExamFromGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExamFromGroupQuery, ExamFromGroupQueryVariables>(ExamFromGroupDocument, options);
      }
export function useExamFromGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExamFromGroupQuery, ExamFromGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExamFromGroupQuery, ExamFromGroupQueryVariables>(ExamFromGroupDocument, options);
        }
export function useExamFromGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExamFromGroupQuery, ExamFromGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExamFromGroupQuery, ExamFromGroupQueryVariables>(ExamFromGroupDocument, options);
        }
export type ExamFromGroupQueryHookResult = ReturnType<typeof useExamFromGroupQuery>;
export type ExamFromGroupLazyQueryHookResult = ReturnType<typeof useExamFromGroupLazyQuery>;
export type ExamFromGroupSuspenseQueryHookResult = ReturnType<typeof useExamFromGroupSuspenseQuery>;
export type ExamFromGroupQueryResult = Apollo.QueryResult<ExamFromGroupQuery, ExamFromGroupQueryVariables>;
export const PublicGroupsDocument = gql`
    query PublicGroups($input: ExamSelectionInput!) {
  publicGroups {
    exam(input: $input) {
      definition {
        answers {
          biddingSequence {
            bidCard {
              color
              value
            }
            declaration
            shouldAlert
          }
          halfAnswers {
            bidCard {
              color
              value
            }
            declaration
            shouldAlert
          }
          matchingAnswers {
            bidCard {
              color
              value
            }
            declaration
            shouldAlert
          }
        }
        cards {
          color
          value
        }
        initialBiddingSequence {
          bidCard {
            color
            value
          }
          declaration
          shouldAlert
        }
      }
      difficultyLevel
      id
      name
      tags
      comment
      updatedAt
      vulnerability
      revisionId
    }
  }
}
    `;

/**
 * __usePublicGroupsQuery__
 *
 * To run a query within a React component, call `usePublicGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicGroupsQuery(baseOptions: Apollo.QueryHookOptions<PublicGroupsQuery, PublicGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicGroupsQuery, PublicGroupsQueryVariables>(PublicGroupsDocument, options);
      }
export function usePublicGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicGroupsQuery, PublicGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicGroupsQuery, PublicGroupsQueryVariables>(PublicGroupsDocument, options);
        }
export function usePublicGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublicGroupsQuery, PublicGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicGroupsQuery, PublicGroupsQueryVariables>(PublicGroupsDocument, options);
        }
export type PublicGroupsQueryHookResult = ReturnType<typeof usePublicGroupsQuery>;
export type PublicGroupsLazyQueryHookResult = ReturnType<typeof usePublicGroupsLazyQuery>;
export type PublicGroupsSuspenseQueryHookResult = ReturnType<typeof usePublicGroupsSuspenseQuery>;
export type PublicGroupsQueryResult = Apollo.QueryResult<PublicGroupsQuery, PublicGroupsQueryVariables>;
export const PublicGroupsItemsDocument = gql`
    query PublicGroupsItems($input: ExamsSelectionInput!) {
  publicGroups {
    exams(input: $input) {
      items {
        id
      }
    }
  }
}
    `;

/**
 * __usePublicGroupsItemsQuery__
 *
 * To run a query within a React component, call `usePublicGroupsItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicGroupsItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicGroupsItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicGroupsItemsQuery(baseOptions: Apollo.QueryHookOptions<PublicGroupsItemsQuery, PublicGroupsItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicGroupsItemsQuery, PublicGroupsItemsQueryVariables>(PublicGroupsItemsDocument, options);
      }
export function usePublicGroupsItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicGroupsItemsQuery, PublicGroupsItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicGroupsItemsQuery, PublicGroupsItemsQueryVariables>(PublicGroupsItemsDocument, options);
        }
export function usePublicGroupsItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublicGroupsItemsQuery, PublicGroupsItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicGroupsItemsQuery, PublicGroupsItemsQueryVariables>(PublicGroupsItemsDocument, options);
        }
export type PublicGroupsItemsQueryHookResult = ReturnType<typeof usePublicGroupsItemsQuery>;
export type PublicGroupsItemsLazyQueryHookResult = ReturnType<typeof usePublicGroupsItemsLazyQuery>;
export type PublicGroupsItemsSuspenseQueryHookResult = ReturnType<typeof usePublicGroupsItemsSuspenseQuery>;
export type PublicGroupsItemsQueryResult = Apollo.QueryResult<PublicGroupsItemsQuery, PublicGroupsItemsQueryVariables>;
export const ExamResultsSaveDocument = gql`
    mutation ExamResultsSave($input: SaveExamResultsInput!) {
  examResultsSave(input: $input) {
    ... on SaveExamResultsFailureView {
      reason
    }
    ... on SaveExamResultsSuccessView {
      examResultId
    }
  }
}
    `;
export type ExamResultsSaveMutationFn = Apollo.MutationFunction<ExamResultsSaveMutation, ExamResultsSaveMutationVariables>;

/**
 * __useExamResultsSaveMutation__
 *
 * To run a mutation, you first call `useExamResultsSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamResultsSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examResultsSaveMutation, { data, loading, error }] = useExamResultsSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamResultsSaveMutation(baseOptions?: Apollo.MutationHookOptions<ExamResultsSaveMutation, ExamResultsSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExamResultsSaveMutation, ExamResultsSaveMutationVariables>(ExamResultsSaveDocument, options);
      }
export type ExamResultsSaveMutationHookResult = ReturnType<typeof useExamResultsSaveMutation>;
export type ExamResultsSaveMutationResult = Apollo.MutationResult<ExamResultsSaveMutation>;
export type ExamResultsSaveMutationOptions = Apollo.BaseMutationOptions<ExamResultsSaveMutation, ExamResultsSaveMutationVariables>;
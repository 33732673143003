import * as Types from '../../../graphql/types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PasswordResetRequestMutationVariables = Types.Exact<{
  input: Types.PasswordResetRequestInput;
}>;


export type PasswordResetRequestMutation = { __typename?: 'Mutation', passwordResetRequest: { __typename?: 'PasswordResetRequestOutput', resetTokenExpiresAt?: any | null, email: string } };


export const PasswordResetRequestDocument = gql`
    mutation PasswordResetRequest($input: PasswordResetRequestInput!) {
  passwordResetRequest(input: $input) {
    resetTokenExpiresAt
    email
  }
}
    `;
export type PasswordResetRequestMutationFn = Apollo.MutationFunction<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>;

/**
 * __usePasswordResetRequestMutation__
 *
 * To run a mutation, you first call `usePasswordResetRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetRequestMutation, { data, loading, error }] = usePasswordResetRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordResetRequestMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>(PasswordResetRequestDocument, options);
      }
export type PasswordResetRequestMutationHookResult = ReturnType<typeof usePasswordResetRequestMutation>;
export type PasswordResetRequestMutationResult = Apollo.MutationResult<PasswordResetRequestMutation>;
export type PasswordResetRequestMutationOptions = Apollo.BaseMutationOptions<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>;
import { useEffect } from 'react';
import { ExamsSummaryOrderBy, ExamsSummaryOrderDirection, LeaderboardType } from '../../../graphql/types.gen.ts';
import { useLastGroupQuery } from '../../FormPage/LoginPage/loginPage.gen.ts';
import { useBackofficeStatisticsLeaderboardsLazyQuery, useBackofficeStatisticsPublicLeaderboardsLazyQuery, useBackofficeStatisticsPublicQuery, useBackofficeStatisticsQuery } from '../../Statistics/statistics.gen.ts';

export const useProfilStatistics = () => {
    const { data: chosenGroupData } = useLastGroupQuery();
    const chosenGroup = chosenGroupData?.me?.recentlyUsedGroupId || 'general';

    const { data: statisticsData } = useBackofficeStatisticsQuery({
        variables: {
            input: {
                pagination: {
                    limit: 100,
                    offset: 0,
                },
            },
            input2: {
                ordering: {
                    orderBy: ExamsSummaryOrderBy.Tag,
                    orderDirection: ExamsSummaryOrderDirection.Desc,
                },
            },
            code: chosenGroup,
        },
    });
    const { data: statisticsPublicData } = useBackofficeStatisticsPublicQuery({
        variables: {
            input: {
                pagination: {
                    limit: 100,
                    offset: 0,
                },
            },
            input2: {
                ordering: {
                    orderBy: ExamsSummaryOrderBy.Tag,
                    orderDirection: ExamsSummaryOrderDirection.Desc,
                },
            },
        },
    });

    const [getLeaderboardsActive, { data: leaderboardsDataActive }] = useBackofficeStatisticsLeaderboardsLazyQuery({
        variables: {
            selection: {
                pagination: {
                    limit: 5,
                    offset: 0,
                },
                setup: {
                    type: LeaderboardType.MostActive,
                },
            },
            code: chosenGroup,
        },
    });
    const [getLeaderboardsRatio, { data: leaderboardsDataRatio }] = useBackofficeStatisticsLeaderboardsLazyQuery({
        variables: {
            selection: {
                pagination: {
                    limit: 5,
                    offset: 0,
                },
                setup: {
                    type: LeaderboardType.HighestScore,
                },
            },
            code: chosenGroup,
        },
    });

    const [getPublicLeaderboardsActive, { data: leaderboardsPublicDataActive }] = useBackofficeStatisticsPublicLeaderboardsLazyQuery({
        variables: {
            selection: {
                pagination: {
                    limit: 5,
                    offset: 0,
                },
                setup: {
                    type: LeaderboardType.MostActive,
                },
            },
        },
    });
    const [getPublicLeaderboardsRatio, { data: leaderboardsPublicDataRatio }] = useBackofficeStatisticsPublicLeaderboardsLazyQuery({
        variables: {
            selection: {
                pagination: {
                    limit: 5,
                    offset: 0,
                },
                setup: {
                    type: LeaderboardType.HighestScore,
                },
            },
        },
    });
    useEffect(() => {
        if (chosenGroup && chosenGroup !== 'general') {
            getLeaderboardsRatio();
            getLeaderboardsActive();
        } else {
            getPublicLeaderboardsRatio();
            getPublicLeaderboardsActive();
        }
    }, [chosenGroup]);

    const leaderboardsActivityData = chosenGroup && chosenGroup !== 'general' ? leaderboardsDataActive?.group.leaderboard : leaderboardsPublicDataActive?.publicGroups[0].leaderboard;
    const leaderboardsRatioData = chosenGroup && chosenGroup !== 'general' ? leaderboardsDataRatio?.group.leaderboard : leaderboardsPublicDataRatio?.publicGroups[0].leaderboard;
    return { statisticsData, statisticsPublicData, leaderboardsActivityData, leaderboardsRatioData };
};

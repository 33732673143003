import React, { PropsWithChildren } from 'react';
import { Footer } from '../../components/Footer/Footer.tsx';
import { Header } from '../../components/Header/Header.tsx';
import Hero from '../../components/Hero/Hero.tsx';
import './Page.scss';

interface IPageProps extends PropsWithChildren {
    title: string;
    theme: 'light' | 'dark';
}

export const Page = (props: IPageProps) => {
    const { title, children, theme } = props;
    return (
        <div className={`page page--${theme} ${theme}`}>
            <div className="content">
                <Header light={theme === 'light'} />
                <Hero title={title} />
                <div className="page__container container">
                    <div className="row">{children}</div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
